import Image from "@atoms/Image";
import Text from "@atoms/Text";
import { BannerData } from "@helpers/homepageData";
import texts from '@components/Homepage/en.json';
import DiscoverBondCard from "./DiscoverBondCard";

const DiscoverBonds = () => {
    return (
        <div className="flex flex-col gap-8">
            {BannerData?.map((item, index) => (
                <DiscoverBondCard key={index} {...item} />
            ))}
        </div>
    );
}

export default DiscoverBonds;