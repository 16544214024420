import {Image} from '@ui/atoms';
import {motion, useViewportScroll, useTransform} from 'framer-motion';
import {useEffect, useState} from 'react';

const ParallaxComponent = () => {
    const {scrollY} = useViewportScroll();

    const [startAnimationAt, setStartAnimationAt] = useState(0);

    const yRange = useTransform(
        scrollY,
        [startAnimationAt, startAnimationAt + 100],
        [0, 100]
    );

    useEffect(() => {
        const updateStartAnimationAt = () => {
        };

        window.addEventListener('load', updateStartAnimationAt);
        window.addEventListener('resize', updateStartAnimationAt);

        return () => {
            window.removeEventListener('load', updateStartAnimationAt);
            window.removeEventListener('resize', updateStartAnimationAt);
        };
    }, []);

    return (
        <div className="relative h-full">
            <div className="relative flex justify-end h-full">
                <motion.div className="relative flex justify-end ml-auto mascot">
                    <Image
                        src="/images/coin-homepage.png"
                        className="absolute left-4 xl:left-6 w-[76px] h-[58px] coin animate-bounce top-24 xl:top-[124px]"
                    />
                    <motion.div
                        style={{
                            position: 'sticky',
                            top: '0',
                            y: yRange,
                        }}
                    >
                        <Image
                            src="/images/homepage/JiraafMascot.webp"
                            alt="Jiraaf_Mascot"
                            className="md:w-[348px] xl:w-[398px] h-[483px] md:h-[566px] xl:h-[647px]"
                        />
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
};

export default ParallaxComponent;
