import Image from "@atoms/Image";
import Text from "@atoms/Text";

const DiscoverBondCard = ({ image, header, description }) => {
    return (
        <div className="flex items-center gap-5 md:hidden rounded-xl w-full max-w-[278px] mx-auto">
            <Image src={image} alt={header} className="self-start w-8 h-8"/>
            <div className="">
                <Text
                    content={header}
                    className="text-primary-500 p4-semibold"
                />
                <Text
                    content={description}
                    className="text-basicBlack p4-regular"
                />
            </div>
        </div>
    );
}

export default DiscoverBondCard;